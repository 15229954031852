<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Salary Correction</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'employees'}">
                        <span>Employees</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name:'employee-details', params:{id:$route.params.emp_id}}">{{employee_salary.employee_name}} [IES-{{$route.params.emp_id}}]</router-link>
                </li>
                <li class="breadcrumb-item active">Employee Salary Correction</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:submit.prevent="updateEmployeeSalary()">
                    <div class="form-group row">
                        <label class="col-md-3">
                            Emp ID
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            IES-{{employee_salary.emp_id}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Employee Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            {{employee_salary.employee_name}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Offered Gross per Year
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                v-model="employee_salary.offered_gross_per_year"
                                v-on:input="getOfferedGrossPerYear"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                type="text"
                                class="form-control"
                                aria-describedby="reasonHelpInline"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Offered Gross per Month
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            {{employee_salary.offered_gross_per_month}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Effective From
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-row>
                                <b-col lg="6" sm="12" class="mb-1rem">
                                    <div class="form-group row">
                                        <label class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap">
                                            Month
                                            <span class="text-danger" style="font-weight:bold;">*</span>
                                        </label>
                                        <div class="col-lg-9 col-md-10 col-sm-12">
                                            <b-form-select
                                                v-model="month"
                                                :options="months"
                                                disabled-field="notEnabled"
                                            ></b-form-select>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col lg="6" sm="12" class="mb-1rem">
                                    <div class="form-group row">
                                        <label class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap">
                                            Year
                                            <span class="text-danger" style="font-weight:bold;">*</span>
                                        </label>
                                        <div class="col-lg-9 col-md-10 col-sm-12">
                                            <b-form-select
                                                v-model="year"
                                                :options="years"
                                                disabled-field="notEnabled"
                                            ></b-form-select>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="updateEmployeeSalary()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            employee_salary_slabs: [
                {value:"", text:"select salary slab"}
            ],
            employee_salary:{
                effective_from: "",
                offered_gross_per_month:"",
                offered_gross_per_year:"",
                emp_id:"",
                employee_name:"",
                id: ""
            },
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            from_date: new Date().getFullYear() - 1,
            to_date: new Date().getFullYear() + 1, 
            month:new Date().getMonth()+1,
            year:'',
            years: [],
            months: [
                { value: "1", text: "January" },
                { value: "2", text: "February" },
                { value: "3", text: "March" },
                { value: "4", text: "April" },
                { value: "5", text: "May" },
                { value: "6", text: "June" },
                { value: "7", text: "July" },
                { value: "8", text: "August" },
                { value: "9", text: "September" },
                { value: "10", text: "October" },
                { value: "11", text: "November" },
                { value: "12", text: "December" }
            ],
            date:'01',
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_SALARY");
        if (this.hasPermission('CAN_MANAGE_SALARY')) {
            this.getEmployeesSalarySlabs();
        }
        this.getEmployeeSalary();
        this.getYears();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getYears() {
            let years = [];
            let year = (new Date().getFullYear()) + 1;
            for (var index = 2010; index <= year; index++) {
                years.push({
                    value: index, text:index
                })
            }
            this.years = years;
            this.year = new Date().getFullYear();
        },

        getEmployeeSalary() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.emp_id
            };
            this.axios
                .get(this.$api.get_employee_salaries, query)
                .then(response => {
                    let result = response.data.data;
                    this.year = new Date(result.effective_from).getFullYear();
                    this.month = new Date(result.effective_from).getMonth()+1;
                    this.employee_salary.emp_id = result.emp_id;
                    this.employee_salary.employee_name = result.employee.full_name;
                    this.employee_salary.offered_gross_per_month = result.offered_gross_per_month;
                    this.employee_salary.offered_gross_per_year = result.offered_gross_per_year;
                    this.employee_salary.id = result.id;
                    this.api_error = "";
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        getEmployeesSalarySlabs() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            this.axios.get(this.$api.get_salary_slabs, query)
                .then(response => {
                    response.data.data.forEach(employee_salary_slab => {
                        this.employee_salary_slabs.push({
                            value:employee_salary_slab.id, text: employee_salary_slab.name
                        })
                    })
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        updateEmployeeSalary() {
            this.startProgressBar();
            if (this.month.toString().length<2) {
                let month = '0'+this.month;
                this.employee_salary.effective_from = this.year+'-'+month+'-'+this.date;
            } else {
                this.employee_salary.effective_from = this.year+'-'+this.month+'-'+this.date;
            }
            this.employee_salary['emp_id'] = this.$route.params.emp_id;
            this.axios
                .post(this.$api.update_employee_salary, this.employee_salary, this.getAuthHeaders())
                .then(() => {
                    this.updateProgressBar(true);
                    this.api_error = "";
                    this.$router.go(-1);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        getOfferedGrossPerYear(event) {
            if (event.target.value) {
                this.employee_salary.offered_gross_per_month = event.target.value/12;
                this.employee_salary.offered_gross_per_month = this.employee_salary.offered_gross_per_month.toFixed(2);
            }
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>